import React from "react"
import qs from "qs"
import ImgSections from "../components/img-sections"
import Layout from "../components/layout"
import SEO from "../components/seo"

const zenToHan = str => {
  return str.replace(/[Ａ-Ｚａ-ｚ０-９]/g, function (s) {
    return String.fromCharCode(s.charCodeAt(0) - 0xfee0)
  })
}

const paypalReady = callback => {
  // avoid to load twice
  if (!window.paypal && !document.getElementById("PAYPAL_SCRIPT")) {
    // paypal loading
    // refer: https://developer.paypal.com/docs/checkout/troubleshoot/performance/
    const src = `https://www.paypal.com/sdk/js?currency=JPY&client-id=${process.env.PAYPAL_CLIENT_ID}`
    const script = document.createElement("script")
    script.setAttribute("src", src)
    script.setAttribute("id", "PAYPAL_SCRIPT")
    document.head.appendChild(script)
  }

  const checkPaypalObj = () => {
    if (window.paypal && document.getElementById("paypal-button-container")) {
      callback()
    } else {
      setTimeout(checkPaypalObj, 500)
    }
  }
  checkPaypalObj()
}

const initializePaypal = location => {
  if (typeof window === `undefined`) return

  const queryObject = qs.parse(location.search.replace(/^\?/, ""))
  const defaultPrice = queryObject.price || null

  paypalReady(() => {
    const container = document.getElementById("paypal-button-container")
    if (defaultPrice) {
      document.getElementById("paypal-price").value = defaultPrice
    }
    if (container.className === "paypal-attached") {
      return
    }
    container.className = "paypal-attached"
    window.paypal
      .Buttons({
        createOrder: function (data, actions) {
          // This function sets up the details of the transaction, including the amount and line item details.
          const price = zenToHan(document.getElementById("paypal-price").value)
          return actions.order.create({
            purchase_units: [
              {
                amount: {
                  currency_code: "JPY",
                  value: price,
                },
              },
            ],
          })
        },
        onApprove: function (data, actions) {
          // This function captures the funds from the transaction.
          return actions.order.capture().then(
            details => {
              // This function shows a transaction success message to your buyer.
              alert("お支払いが正常に完了しました。")
            },
            error => {
              console.log(error)
              alert(
                "お支払いが正常に完了しませんでした。お手数ですがメールかお問合せフォームにてご連絡いただければと存じます。"
              )
            }
          )
        },
      })
      .render("#paypal-button-container")
  })
}

const Page = ({ data, location }) => {
  initializePaypal(location)

  const breadPaths = [
    {
      text: "お支払い",
    },
  ]

  const pageTitle = "お支払い"
  const sections = [
    {
      text: `
        <p>以下にお支払い金額を入力の上、ボタンを押して決済を進めて頂けますようお願いいたします。</p>
        <div class="pinsel-PriceInput">
          <label>金額: <input type="text" value="" id="paypal-price" /></label>
        </div>
        <div id="paypal-button-container"></div>
      `,
    },
  ]

  return (
    <Layout breadPaths={breadPaths}>
      <SEO title={pageTitle} />
      <h1 className="text-4xl md:text-5xl leading-tight font-bold py-2gap lg:py-1col">
        {pageTitle}
      </h1>
      <ImgSections sectionData={sections} />
    </Layout>
  )
}

export default Page
